import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from "react-plotly.js/factory";
import {getScatterData} from "../assets/utils";

const LPLOT = ({title, dataString, width, legend}) => {
    const Plot = createPlotlyComponent(Plotly);
    let [plotData, numCategories] = getScatterData(dataString, 0, 3, 2)

    let graphWidth = window.innerWidth * width
    let graphHeight = width * window.innerHeight * 1.5
    let legendX

    if (graphWidth < 350) {
        graphWidth = 350
    }

    if (graphHeight < 400) {
        graphHeight = 400
    }

    if (graphHeight > 800) {
        graphHeight = 800
    }

    if (graphHeight < 500) {
        legendX = -1.3 * numCategories / 10
    } else if (graphHeight < 600) {
        legendX = -0.7 * numCategories / 10
    } else {
        legendX = -0.5 * numCategories / 10
    }

    return (
        <>
            <Plot data={plotData}
                  layout={{
                      font: {
                          family: 'Arial'
                      },
                      title: {
                          text: title,
                          font: {
                              size: 18,
                          },
                          xref: 'paper',
                          x: 0.5,
                          y: 0.9,
                      },
                      showlegend: true,
                      legend: {
                          x: legend=='right' ? 1 : 0,
                          y: legend=='right' ? 0 : legendX-0.1,
                          bgcolor: '#f7f7f7',
                          bordercolor: '#f0f0f0',
                          borderwidth: 5,
                          font: {
                            size: 12,
                            color: '#333'
                          }
                      },

                      width: graphWidth,
                      height: graphHeight + 60,
                      margin: {
                        l: 60,
                        r: 20,
                        t: 70
                      },

                      yaxis: {
                          title: {
                              text: 'Publication Count',
                              font: {
                                  size: 15,
                                  color: '#7f7f7f'
                              }
                          }
                      },

                      xaxis: {
                        tickangle: -45,
                        tickfont: {
                          size: 12,
                          color: '#333'
                        },
                        tickcolor: '#f0f0f0'
                      }
                  }}/>
        </>
    );
}

export default LPLOT;