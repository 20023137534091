import { useEffect, useState } from "react";
import $ from 'jquery'
import { getLegendObjects } from "../assets/utils";
import ClusterText2 from "./ClusterText2";

const Network4 = ({ data }) => {
    const networkWidth = 800;

    const [clusterNumber, setClusterNumber] = useState(null)
    const [isClicked, setIsClicked] = useState(false)

    let clusterArray = ['79', '96', '6', '34', '101', '38', '54', '39', '30', '173', '36']
    let filePrefix = './imgs-RELandCOOP-citation/'

    function findPos(obj) {
        var curleft = 0, curtop = 0;
        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj === obj.offsetParent);
            return { x: curleft, y: curtop };
        }
        return undefined;
    }

    const getColor = (ev) => {
        const canvas = document.getElementById('view04'),
            context = canvas.getContext('2d');

        const pos = findPos(canvas);
        const x = ev.pageX - pos.x;
        const y = ev.pageY - pos.y;

        const p = context.getImageData(x, y, 1, 1).data;
        const r = p[0].toString()
        const g = p[1].toString()
        const b = p[2].toString()
        const a = p[3].toString()

        $('.legend-button').removeClass('selected-legend-button')
        Object.entries(data).forEach(pair => {
            const colors = pair[1]
            if (r === colors.r && g === colors.g && b === colors.b) {
                setClusterNumber(pair[0])
            }
        })
    }

    useEffect(() => {
        highlightButton('l2-btn-' + clusterNumber)
        highlightItem('l2-itm-' + clusterNumber)
    }, [clusterNumber])

    const displayInfo = (ev) => {
        const canvas = document.getElementById('view04'),
            context = canvas.getContext('2d');

        const pos = findPos(canvas);
        const x = ev.pageX - pos.x;
        const y = ev.pageY - pos.y;

        const p = context.getImageData(x, y, 1, 1).data;
        const r = p[0].toString()
        const g = p[1].toString()
        const b = p[2].toString()

        if (colorDict[r + g + b]) {
            $('#test-square').textContent = colorDict[r + g + b]
        }
    }

    const colorDict = {}

    function draw(scale, translatePos) {
        const canvas = document.getElementById('view04')
        const context = canvas.getContext('2d');
        context.save();
        context.translate(translatePos.x, translatePos.y);
        context.scale(scale, scale);

        let base_image = new Image();
        base_image.src = './imgs-RELandCOOP-citation/RelAndCoop.png';
        base_image.onload = function () {
            // clear canvas
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(base_image, 0, 0, networkWidth, networkWidth);
            context.restore();
        }
    }

    useEffect(() => {
        const canvas = document.getElementById('view04')
        const context = canvas.getContext('2d');

        canvas.addEventListener('click', getColor)

        const initTranslatePos = {
            x: 0,
            y: 0
        };
        const initScale = 1;

        var translatePos = { x: initTranslatePos.x, y: initTranslatePos.y }

        var scale = initScale;
        var scaleMultiplier = 0.8;
        var startDragOffset = {};
        var mouseDown = false;

        // add button event listeners
        document.getElementById("plus04").addEventListener("click", function () {
            scale /= scaleMultiplier;
            const maxWidth = networkWidth * scale;
            const newX = translatePos.x === 0
                ? -100
                : -100 +
                (-(1 / scaleMultiplier)
                    * Math.sqrt(
                        Math.pow(translatePos.x, 2) + Math.pow(translatePos.y, 2)
                    )
                    * Math.cos(Math.atan(translatePos.y / translatePos.x)));
            const newY = translatePos.y === 0
                ? -100
                : -100 +
                (-(1 / scaleMultiplier)
                    * Math.sqrt(Math.pow(translatePos.x, 2) + Math.pow(translatePos.y, 2))
                    * Math.sin(Math.atan(translatePos.y / translatePos.x)));
            if (newX + maxWidth - networkWidth >= 0 && newX <= 0) {
                translatePos.x = newX;
            }
            if (newY + maxWidth - networkWidth >= 0 && newY <= 0) {
                translatePos.y = newY;
            }
            draw(scale, translatePos);
        }, false);

        document.getElementById("minus04").addEventListener("click", function () {
            if (scale * scaleMultiplier < initScale) {
                return false;
            }
            scale *= scaleMultiplier;
            const maxWidth = networkWidth * scale;
            const newX = translatePos.x === 0
                ? 100
                : 100 +
                (-scaleMultiplier
                    * Math.sqrt(
                        Math.pow(translatePos.x, 2) + Math.pow(translatePos.y, 2)
                    )
                    * Math.cos(Math.atan(translatePos.y / translatePos.x)));
            const newY = translatePos.y === 0
                ? 100
                : 100 +
                (-scaleMultiplier
                    * Math.sqrt(Math.pow(translatePos.x, 2) + Math.pow(translatePos.y, 2))
                    * Math.sin(Math.atan(translatePos.y / translatePos.x)));

            if (newX + maxWidth - networkWidth >= 0 && newX <= 0) {
                translatePos.x = newX;
            }
            if (newY + maxWidth - networkWidth >= 0 && newY <= 0) {
                translatePos.y = newY;
            }
            if (translatePos.x + maxWidth - networkWidth < 0) {
                translatePos.x = networkWidth - maxWidth;
            }
            if (translatePos.x > 0) {
                translatePos.x = 0;
            }
            if (translatePos.y + maxWidth - networkWidth < 0) {
                translatePos.y = networkWidth - maxWidth;
            }
            if (translatePos.y > 0) {
                translatePos.y = 0;
            }
            draw(scale, translatePos);
        }, false);

        document.getElementById("reset04").addEventListener("click", function () {
            scale = 1;
            translatePos = {
                x: 0,
                y: 0
            };
            draw(scale, translatePos);
        }, false)


        const getPosFromEvt = function (evt) {
            if ('clientX' in evt) {
                return { x: evt.clientX, y: evt.clientY };
            }
            if ('targetTouches' in evt) {
                return { x: evt.targetTouches[0].clientX, y: evt.targetTouches[0].clientY };
            }
            return { x: 0, y: 0 }; // should not happen
        }
        const onDown = function (evt) {
            if (evt.type == 'touchstart') {
                evt.preventDefault();
            }
            mouseDown = true;
            startDragOffset.x = getPosFromEvt(evt).x - translatePos.x;
            startDragOffset.y = getPosFromEvt(evt).y - translatePos.y;
        }
        const onUp = function (evt) {
            if (evt.type == 'touchend') {
                evt.preventDefault();
            }
            mouseDown = false;
        }
        const onMove = function (evt) {
            if (evt.type == 'touchmove') {
                evt.preventDefault();
            }
            if (mouseDown) {
                const maxWidth = networkWidth * scale;
                const newX = getPosFromEvt(evt).x - startDragOffset.x;
                if (newX + maxWidth - networkWidth >= 0 && newX <= 0) {
                    translatePos.x = newX;
                }
                const newY = getPosFromEvt(evt).y - startDragOffset.y;
                if (newY + maxWidth - networkWidth >= 0 && newY <= 0) {
                    translatePos.y = newY;
                }
                draw(scale, translatePos);
            }
        }

        // add event listeners to handle screen drag
        canvas.addEventListener("mousedown", onDown);
        canvas.addEventListener("touchstart", onDown);

        canvas.addEventListener("mouseup", onUp);
        canvas.addEventListener("touchend", onUp);

        canvas.addEventListener("mouseover", function (evt) {
            mouseDown = false;
        });

        canvas.addEventListener("mouseout", function (evt) {
            mouseDown = false;
        });

        canvas.addEventListener("mousemove", onMove);
        canvas.addEventListener("touchmove", onMove);

        draw(scale, translatePos);

        Object.entries(data).forEach(pair => {
            const colors = pair[1]
            colorDict[colors.r + colors.g + colors.b] = pair[0]
        })

        $('.legend-btn').on('click', () => {
            setIsClicked(true)
        })
    }, [])

    const highlightButton = (btnID) => {
        $('.legend-btn').removeClass('selected-legend-button')
        $('#' + btnID).addClass('selected-legend-button')
    }

    const highlightItem = (itemID) => {
        $('.legend-item').removeClass('selected-legend-item')
        $('#' + itemID).addClass('selected-legend-item')
    }

    return (
        <>
            <div style={{ width: "100%", padding: "30px 30px 30px 30px", 'margin': '0 0 50px 0', backgroundColor: "#25313C", borderRadius: "5px", color: "white" }}>
                <div>
                    <b style={{ fontWeight: 'bold' }}>See the network grow over time. Each node is an author, and a connection between authors represents one or more coauthored publication(s).</b>
                    <br />Below the video, you can investigate and interact with the network.
                    <div style={{ 'width': '100%', 'marginTop': '20px', 'display': 'flex', 'justifyContent': 'center' }}>
                        <iframe width="95%" height="700" style={{ 'border': '2px solid black' }} src="https://www.youtube.com/embed/9qQ-Re0pxpk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", padding: "30px 30px 30px 30px", backgroundColor: "#25313C", borderRadius: "5px", color: "white" }}>
                <div class="network-help-text">
                    <div style={{ fontWeight: 'bold', fontSize: '18px', margin: "0 0 20px 0" }}>Investigate the network below.</div>
                    Here are some tips to get started.
                    <ul>
                        <li>Zoom in/out with the buttons below the network. It is easier to click nodes when zoomed in.</li>
                        <li>Click and drag when zoomed in to move the image.</li>
                        <li>Use F11 to make your browser full screen.</li>
                        <li>You can also click on clusters by color in the legend on the right.</li>
                    </ul>
                </div>
                {
                    !clusterNumber &&
                    <div style={{ 'width': '100%', 'margin': '50px 0 20px 0', 'display': 'flex', 'justifyContent': 'center' }}>
                        <b style={{ fontSize: '15px', color: "#E47639" }}>No cluster selected, click on a node from the network below to display
                            additional information</b>
                    </div>
                }
                {
                    isClicked &&
                    <div style={{ 'width': '100%', 'margin': '50px 0 20px 0', 'display': 'flex', 'justifyContent': 'center' }}>
                        <b style={{ fontSize: '15px', color: "#E47639" }}>Scroll down to view a word cloud and description for the selected
                            cluster.</b>
                    </div>
                }
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", 'height': '900px' }}>
                    <div className="button-wrapper" style={{ margin: "0 20px 0 0" }}>
                        <input type="button" id="plus04" value="+" /><br />
                        <input type="button" id="minus04" value="-" /><br />
                        <input type="button" id="reset04" value={'\u21BB' /* refresh symbol */} />
                    </div>
                    <div id={'citationCar'}>
                        <canvas id={'view04'} width={800} height={800} />
                    </div>
                    <div style={{ overflow: "scroll", height: "800px", margin: "0 0 0 30px" }}>
                        {
                            Object.entries(getLegendObjects(clusterArray, false, null, true)).map(([clusterID, clusterObj]) => {
                                return (
                                    <div className={'legend-item'} style={{ display: "flex", flexDirection: "row" }} id={'l2-itm-' + clusterID}>
                                        <div className={'btn legend-btn'}
                                            style={{ 'background': clusterObj.color }}
                                            onClick={
                                                () => {
                                                    setClusterNumber(clusterID)
                                                    highlightButton('l2-btn-' + clusterID)
                                                    highlightItem('l2-itm-' + clusterID)
                                                }
                                            } id={'l2-btn-' + clusterID} />
                                        <div className={'legend-text'}>{clusterObj.text}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    (clusterArray.indexOf(clusterNumber) !== -1) && (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                            <div className={'cloud-img'} style={{ 'paddingLeft': '40px' }}>
                                <img src={filePrefix + clusterNumber + ' word cloud.png'} className={'cloud-image'} />
                            </div>
                            <div className={'cloud-text'} style={{ margin: "30px 0 50px 0" }}>
                                <ClusterText2 textNumber={clusterNumber} />
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default Network4;
