import { useViewport } from "../assets/utils";

const LDA = ({ iframe, isCnot, ldaNum }) => {

    // iframe is currently 1210px by 780px
    const { width } = useViewport();
    const breakpoint = 1600;

    return (
        <>
            <div style={{ width: "100%", padding:"0 0 0px 0", backgroundColor: "#25313C", borderRadius: "5px" }}>
                <div style={{ display: "flex", flexDirection:"column", justifyContent: "center" }}>
                
                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "white" }}>
                        <iframe src={'./' + iframe}
                            width={1200}
                            height={900} />
                    </div>
                    {!isCnot &&
                        (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={'./lda-tree.png'} style={{ width:"85%" }} />
                            </div>
                        )
                    }

                </div>
                {
                    (isCnot && <div style={{ color:"white", padding:"0 0 50px 0" }}>
                        <b className={'lda-text-spacing'}>
                            CooperationNOTReligion Topic Model
                        </b>

                        <p className={'lda-text-spacing'}>
                            The CooperationNOTReligion dataset contains eleven distinct topics, ten of which are grouped
                            into
                            three clusters, though only one of those clusters appears to be based on deep thematic
                            similarities.
                        </p>

                        <p className={'lda-text-spacing'}>
                            The first cluster, including topics 2, 4, 5, 7, and 9, is focused on the study of biological
                            cooperation, with individual topics being distinguished primarily by different methodologies
                            or
                            disciplinary emphases. Classic game theory is central to both 2 and 5, as indicated by the
                            predominance
                            in these two topics of terms such as <i>game, dilemma, prisoner,
                                cooperator,</i> and <i>player</i>. 2 is focused
                            on
                            the evolution of cooperation and analyses of cooperation at the population level, including
                            systems
                            dynamical and network analyses of populations; terms that are key in 2 but missing from 5
                            include:
                            <i>population, evolution, evolutionary, network, dynamic,</i> and <i>spatial</i>. In
                            contrast, 5
                            approaches game
                            theory via experimental psychology, employing controlled experiments where participants
                            decide
                            whether
                            to cooperate, punish, etc. other experimental participants; terms central to 5 but missing
                            from
                            2
                            include: <i>experiment, decision, subject, contribution, choice, offer, preference,
                                dictator,</i> and
                            <i>fairness</i>.
                            9 is focused on cooperation among eusocial insects and includes analyses of the evolution of
                            cooperation
                            among different species as well as the genes that determine different kinds and levels of
                            cooperation;
                            terms that are nearly unique to 9 include: <i>genetic, cell, gene, colony, queen, plant,
                                host,
                                ant,</i> and
                            <i>insect</i>. 4 takes a sociological approach to the study of cooperation in different
                            human
                            societies,
                            including examination of social norms and the role of trust in fostering cooperation; terms
                            that
                            occur
                            almost exclusively in topic 4 include: <i>trust, norm, cognitive, moral,
                                prosocial,</i> and <i>emotion</i>. 7
                            includes
                            ethological studies of cooperation among social animals, including primates, with a special
                            focus on
                            sexually differentiated roles in cooperation; terms that occur almost exclusively in topic 7
                            include:
                            <i>male, female, animal, offspring, sex, reproductive, food, breeding, mating, sexual, pair,
                                primate, </i>
                            and
                            <i> nest</i>.
                        </p>

                        <p className={'lda-text-spacing'}>
                            The second cluster including 1, 3, and 6 appears to be a pseudo-cluster connecting disparate
                            studies
                            based upon superficial thematic similarities. 3 is concerned with cooperation and
                            communication
                            between
                            computer networks, a tell-tale indicator being several terms that occur almost exclusively
                            in
                            this
                            topic: node, algorithm, scheme, user, etc. 6 is focused on developing international policies
                            such as
                            those proposed by the WHO and various NGOs. Key terms that are nearly unique to topic 6
                            include:
                            country, political, international, global, water, government, and climate. Lastly, topic 1
                            appears
                            to
                            include various philosophical, political, and other meta-theoretical approaches to
                            cooperation.
                            Topic 1
                            includes the vast majority of occurrence of terms such as political, culture, cultural,
                            science,
                            and
                            law; the nearly exclusive occurrence of chapter in this topic suggests that much research in
                            this
                            topic
                            occurs in monographs and edited volumes, rather than the peer-reviewed journals that
                            predominate
                            in
                            other topics.
                        </p>

                        <p className={'lda-text-spacing'}>
                            The third cluster includes topics 8 and 11, which, despite overlapping quite closely in the
                            analysis,
                            address significantly different subjects. 8 is focused on studies of education and community
                            development
                            and includes the following key terms that are all-but absent from topic 11: <i>social,
                                child,
                                community,
                                cohesion, capital, student, school, adolescent, bonding,</i> and <i>parent</i>. Topic 11
                            consists mostly of
                            medical
                            clinical trials, which might or might not actually be focused on cooperation (i.e. the
                            connection to
                            cooperation could come from phrases such as "78% of subjects in the initial study cooperated
                            with
                            follow-up interviews"). Terms that are central to 11 but largely missing from 8 include: <i>patient,
                                care,
                                treatment, clinical, disease, disorder, medical, trial, cancer,
                                depression,</i> and <i>symptom</i>. That which
                            unites these otherwise disparate topics is a common research methodology, with all of the
                            following
                            terms being important in both topics: <i>research, study, health, program, data, factor,
                                method,
                                intervention, outcome,</i> and <i>result</i>.
                        </p>

                        <p className={'lda-text-spacing'}>
                            Topic 10 is on an island by itself and it presents a set of terms that render its
                            interpretation
                            difficult. Those terms that are nearly unique to topic 10—<i>variable, men, sample</i>—or
                            are
                            disproportionally
                            represented in topic 10—<i>age, higher, woman, difference</i>—suggests that this topic
                            consists
                            of topical
                            surveys and meta-analyses based on large datasets, probably including a mixture of
                            policy-oriented
                            papers, medical research, and more biological oriented game theoretical research.
                        </p>
                    </div>) || (ldaNum === 2 &&
                        <div style={{ color:"white", padding:"0 0 50px 0" }}>
                            
                            <div style={{ 'margin': '20px 0 50px 100px' }}>
                                <b>Religious Studies Cooperation: (1)</b>
                                <p>muslim, god, theory, society, right, charity, state, secular, identity, science, ritual,
                                    supernatural, government, western, world, law, culture, religion, religious</p>
                                <b>Organ donation: (2)</b>
                                <p>donation, organ, attitude, transplantation, patient, spain, death, donate, hospital,
                                    kidney,
                                    donor, deceased, brain</p>
                                <b>Grab bag: (3)</b>
                                <p>institute, medieval, sfb, imafo, director, tribe, escatological, empire, history,
                                    formation,
                                    vision, urban, comparative, anthropology</p>
                            </div>
                            <p style={{ 'paddingLeft': '50px', 'paddingRight': '50px' }}>
                                In the Religion and Cooperation collection, the optimal topic model described three
                                publication clusters: Religious Studies Cooperation, Organ Donation, and Grab Bag/Misc.
                                However, when we explore the second and third best models (with lower coherence scores),
                                we
                                can see the Religious Studies Cooperation cluster break into some more specific
                                clusters. It
                                is important to note that these models are a less coherent model of the overall group of
                                publications, but because the coherence scores are still above 0.5, we can conclude that
                                these separation of topics in the Religion Studies Cooperation parent cluster still adds
                                some coherence to the collection. The adjacent image demonstrates the cluster hierarchy
                                across LDA models. You can explore each model more deeply by switching tabs between k=3,
                                k=6, and k=8.
                            </p>
                        </div>

                    ) || (ldaNum === 3 &&
                        <div style={{ color:"white", padding:"0 0 50px 0" }}>
                            
                            <div style={{ 'margin': '20px 0 50px 100px' }}>
                                <b>(1)</b>
                                <p>muslim, law, Islam, identity, freedom, dialogue, theology, philosophy, state, god,
                                    society,
                                    political, right, world, public, science, government, secular, western, Christian,
                                    liberal</p>
                                <b>(2)</b>
                                <p>religiosity, prosocial, household, average, income, behavior, ritual, religiosity,
                                    psychological, charitable, data, experiment, bias, game, stress, affiliation</p>
                                <b>(5)</b>
                                <p>child, care, school, home, Jewish, economy, education, procedure, disease, local,
                                    intervention, professional, spiritual </p>
                                <b>(6)</b>
                                <p>Spain, kidney, favorable, attitude, toward, death, brain, favor, living,
                                    transplantation</p>
                                <b>(3)</b>
                                <p>organ, donation, donate, willingness, african, cross-sectional, participant,
                                    spirituality,
                                    belief, patient</p>
                                <b>(4)</b>
                                <p>institute, medieval, lmafo, tribe, anthropology, empire, monastery, history, formation,
                                    vision, urban, comparative</p>
                            </div>
                            <p style={{ 'paddingLeft': '50px', 'paddingRight': '50px' }}>
                                In the Religion and Cooperation collection, the optimal topic model described three
                                publication clusters: Religious Studies Cooperation, Organ Donation, and Grab Bag/Misc.
                                However, when we explore the second and third best models (with lower coherence scores),
                                we
                                can see the Religious Studies Cooperation cluster break into some more specific
                                clusters. It
                                is important to note that these models are a less coherent model of the overall group of
                                publications, but because the coherence scores are still above 0.5, we can conclude that
                                these separation of topics in the Religion Studies Cooperation parent cluster still adds
                                some coherence to the collection. The adjacent image demonstrates the cluster hierarchy
                                across LDA models. You can explore each model more deeply by switching tabs between k=3,
                                k=6, and k=8.
                            </p>
                        </div>

                    ) || (
                        <div style={{ color:"white", padding:"0 0 50px 0" }}>
                            
                            <div style={{ 'margin': '20px 0 50px 100px' }}>
                                <b>(1)</b>
                                <p>muslim, law, Islam, freedom, dialogue, theology, liberal,western, state, political, society,
                                    right, law, science, political, liberal, identity, world, public</p>
                                <b>(7)</b>
                                <p>supernatural, punishment, prosociality,religiosity, prosocial, prosociality, belief,
                                    behavior, ingroup, agent, altruistic, mechanism</p>
                                <b>(2)</b>
                                <p>faithbased, Catholic, psychology, social, cultural, apa, sexual, capital, psychinfo, bias,
                                    service</p>
                                <b>(8)</b>
                                <p>charitable, giving, income, personality, economy, charity, ritual, financial, prayer </p>
                                <b>(6)</b>
                                <p>Jewish,child, care, school, local, education, professional, spiritual, economy, education,
                                    home, procedure</p>
                                <b>(5)</b>
                                <p>Spain, kidney, favorable, attitude, toward, death, brain, favor, living, transplantation</p>
                                <b>(4)</b>
                                <p>organ, donation, donate, willingness, african, cross-sectional, participant, spirituality,
                                    belief, patient</p>
                                <b>(3)</b>
                                <p>institute, medieval, lmafo, tribe, anthropology, empire, monastery, history, formation,
                                    vision, urban, comparative</p>
                            </div>
                            <p style={{ 'paddingLeft': '50px', 'paddingRight': '50px' }}>
                                In the Religion and Cooperation collection, the optimal topic model described three
                                publication clusters: Religious Studies Cooperation, Organ Donation, and Grab Bag/Misc.
                                However, when we explore the second and third best models (with lower coherence scores), we
                                can see the Religious Studies Cooperation cluster break into some more specific clusters. It
                                is important to note that these models are a less coherent model of the overall group of
                                publications, but because the coherence scores are still above 0.5, we can conclude that
                                these separation of topics in the Religion Studies Cooperation parent cluster still adds
                                some coherence to the collection. The adjacent image demonstrates the cluster hierarchy
                                across LDA models. You can explore each model more deeply by switching tabs between k=3,
                                k=6, and k=8.
                            </p>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default LDA;