//After UI is stable, rename and replace App.js
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useEffect } from "react";
import 'react-tabs/style/react-tabs.css';
import LDA from "./components/LDA";
import Author from "./components/Author";
import Institutional from "./components/Institutional";
import Network from "./components/Network";
import "./styles.css";
import Ethnicity from "./components/Ethnicity";
import Gender from "./components/Gender";
import Rank from "./components/Rank";
import Departments from "./components/Departments";
import Home from './components/Home';
import colorsCitationCoop from "./assets/NewCoop1PercOrMoreRGBCodes";
import colorsCoAuthorCoop from "./assets/coauthor_cluster_colors";
import colorsCitationReligANDCoop from "./assets/ReligAndCoopRGBCodes"
import Network2 from "./components/Network2";
import About from "./components/About";
import Network3 from "./components/Network3";
import TermDegree from "./components/TermDegree";
import Network4 from "./components/Network4";
import rcCitationCodes from "./assets/RelAndCoopRGBCodes";

export default function App() {

	useEffect(() => {
		// Default page header on load
		return document.getElementById("headerElement").innerText = "What is SCORE? Or, the Social COnsequences of REligion Project";
	});

	const handleTabClick = (headerValue) => {
		// Avoided using useState, for some reason useState slows down the switching/rendering of each Tab in TabList
		return () => document.getElementById("headerElement").innerText = headerValue;
	}

	return (
		<Tabs forceRenderTabPanel defaultIndex={0}>
			<div className={"box"} style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%" }}>
				<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "15%", backgroundColor: "#041625" }}>
					<div style={{ display: "flex", justifyContent: "center", width: "100%", height: "75px", margin: "30px 0 30px 0" }}>
						<img src="nexusSIM-logo.png" style={{ width: "150px", height: "75px", cursor:"pointer" }}  onClick={() => window.open("https://www.nexussim.org", '_blank')}/>
					</div>
					<div style={{ width: "100%", height: "100%", padding: "0px 15px 15px 15px" }}>
						<TabList style={{ display: "flex", flexDirection: "column", color: "white" }}>
							<Tab onClick={handleTabClick("What is SCORE")}>Home</Tab>
							<Tab onClick={handleTabClick("Topic")}>Topic</Tab>
							<Tab onClick={handleTabClick("Author")}>Author</Tab>
							<Tab onClick={handleTabClick("Ethnicity")}>Ethnicity</Tab>
							<Tab onClick={handleTabClick("Gender")}>Gender</Tab>
							<Tab onClick={handleTabClick("Rank")}>Rank</Tab>
							<Tab onClick={handleTabClick("Institution")}>Institution</Tab>
							<Tab onClick={handleTabClick("Department")}>Department</Tab>
							<Tab onClick={handleTabClick("Network")}>Network</Tab>
							<Tab onClick={handleTabClick("Terminal Degree")}>Terminal Degree</Tab>
							<Tab onClick={handleTabClick("About")}>About</Tab>
						</TabList>
					</div>
				</div>
				<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "85%", padding: "25px", backgroundColor: "#37424e", overflow: "auto" }}>
					<div style={{ display: "flex", alignItems: "center", height: "15%", width: "100%" }}>
						<div id="headerElement" style={{ fontSize: "32px", color: "white", fontWeight: "400" }} />
					</div>
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "90%", width: "100%", }}>
						<div style={{ height: "100%", width: "100%" }}>
							<TabPanel>
								<Home />
							</TabPanel>
							<TabPanel>
								<Tabs forceRenderTabPanel>
									<TabList style={{ color: "white" }}>
										<Tab>Cooperation NOT Religion (k=11, C=0.59)</Tab>
										<Tab>Religion and Cooperation (k=3, C=0.60)</Tab>
										<Tab>R&C (k=6, C=0.53)</Tab>
										<Tab>R&C (k=8, C=0.51)</Tab>
									</TabList>
									<TabPanel>
										<LDA iframe={'lda_optimal_CooperationNotReligion.html'} isCnot={true} />
									</TabPanel>
									<TabPanel>
										<LDA iframe={'lda_optimal_CooperationANDReligion.html'} isCnot={false} ldaNum={2} />
									</TabPanel>
									<TabPanel>
										<LDA iframe={'6k_lda_optimal_CooperationANDReligion.html'} isCnot={false} ldaNum={3} />
									</TabPanel>
									<TabPanel>
										<LDA iframe={'8k_lda_optimal_CooperationANDReligion.html'} isCnot={false} />
									</TabPanel>
								</Tabs>
							</TabPanel>
							<TabPanel>
								<Author />
							</TabPanel>
							<TabPanel>
								<Ethnicity />
							</TabPanel>
							<TabPanel>
								<Gender />
							</TabPanel>
							<TabPanel>
								<Rank />
							</TabPanel>
							<TabPanel>
								<Institutional />
							</TabPanel>
							<TabPanel>
								<Departments />
							</TabPanel>
							<TabPanel>
								<Tabs forceRenderTabPanel>
									<TabList style={{ color: "white" }}>
										<Tab>Cooperation Citation</Tab>
										<Tab>Religion And Cooperation Citation</Tab>
										<Tab>Cooperation Coauthorship</Tab>
										<Tab>Religion and Cooperation Coauthorship</Tab>
									</TabList>
									<TabPanel>
										<Network data={colorsCitationCoop} />
									</TabPanel>
									<TabPanel>
										<Network4 data={rcCitationCodes} />
									</TabPanel>
									<TabPanel>
										<Network2 data={colorsCoAuthorCoop} />
									</TabPanel>
									<TabPanel>
										<Network3 data={colorsCitationReligANDCoop} />
									</TabPanel>
								</Tabs>
							</TabPanel>
							<TabPanel>
								<TermDegree />
							</TabPanel>
							<TabPanel>
								<About />
							</TabPanel>
						</div>
					</div>
				</div>
			</div>
		</Tabs>
	);
}