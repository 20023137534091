const Home = () => {
    return (
        <div style={{ width: "100%", color: "white", fontSize:"20px", padding: "40px", backgroundColor: "#25313C", borderRadius: "5px" }}>
            <div style={{margin:"0 0 30px 0"}}>
                In the realm of scholarly research, understanding the myriad of conversations and discourses can be a monumental task. Through the lens of bibliometrics, we have endeavored to unravel the research intersections between the scientific study of Religion and the study of Cooperation. Our focus on 'Cooperation' in relation to religion was a deliberate choice, guided by previous analyses that revealed a rich interplay of themes where cooperation and religious contexts intertwine. This nexus, seemingly peripheral at first glance, is deeply ingrained in the moral and ethical frameworks of human societies. A prime example of this intersection is the emergence of organ donation as a thematic overlap, illustrating how acts of altruism and religious moral imperatives manifest in the modern context of health and wellbeing. Through field-mapping, we aim not only to chart existing knowledge but also to uncover new connections and insights within these broader research dialogues.
            </div>

            <div style={{margin:"0 0 30px 0"}}>
                At the fascinating crossroads of Cooperation and Religion, we discovered an unexpected yet logical link: studies on organ donation. This finding underscores the complex and often unforeseen ways diverse research topics can intersect. This exploration is rooted in a comprehensive analysis of the Religion field, where we identified significant thematic clusters overlapping with the concept of Cooperation.
            </div>

            <div style={{margin:"0 0 30px 0"}}>
                However, topic analysis is just one facet of our project. Utilizing bibliometric data from the Dimensions database, including authors, citations, and abstracts, we offer insights into the gender and race distributions in these fields, alongside terminal degrees and other author-based comparisons. Additionally, our application allows you to explore co-authorship networks, revealing clusters of research topics among authors and within publication citation networks.
            </div>

            <div style={{margin:"0 0 30px 0"}}>
                This project demonstrates how disparate fields can inform and enrich one another, uncovering the latent unity within human social and cultural constructs. As we navigate this complex knowledge landscape, we invite scholars to join in exploring the nuances and connections that define and shape the intricate relationship between Cooperation and Religion.
            </div>

            <div style={{margin:"0 0 30px 0"}}>
                For a more detailed description of our methodology, please see the About section.
            </div>
        </div>
    )
}

export default Home;