import {
    sortByYear,
    getYearRange, getStackedSums, filterByYear, filterByCollection, getCollectionYearRange
} from "../assets/utils";
import ethnicity from '../assets/EthnicityPubCountByPentaslice'
import LPLOT from "./LPLOT";
import { useState } from "react";
import MultiRangeSlider from "./MultiRangeSlider";
import BSPLOT from "./BSPLOT";

const Ethnicity = () => {

    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const yearRange = getYearRange(ethnicity, 2)
    const [minYear, setMinYear] = useState(yearRange[0])
    const [maxYear, setMaxYear] = useState(yearRange[1] + 4)

    const [dataArray, setDataArray] = useState(fetchData(yearRange[0], yearRange[1]))

    if (isFirstLoad) {
        getCollectionYearRange(ethnicity)
        setIsFirstLoad(false)
    }

    function fetchData(min, max) {
        const [sorted, categories] = sortByYear(ethnicity, 2, 0, min, max)

        const religion2020 = filterByCollection(sorted, 1, 'Religion2020')
        const cooperation2021 = filterByCollection(sorted, 1, 'Cooperation2021')
        const coopANDreligion = filterByCollection(sorted, 1, 'CooperationAndReligion')

        const rcCOMBO = religion2020 + '\n' + cooperation2021

        const sortedCOMBO = sortByYear(rcCOMBO, 2, 0, min, max).toString()

        const filteredDataR = filterByYear(sorted, min, max, 2, 1, 'Religion2020')
        const stackDataR = getStackedSums(filteredDataR, 0, 2, 1, 3)

        const filteredDataC = filterByYear(sorted, min, max, 2, 1, 'Cooperation2021')
        const stackDataC = getStackedSums(filteredDataC, 0, 2, 1, 3)

        const filteredDataB = filterByYear(sorted, min, max, 2, 1, 'CooperationAndReligion')
        const stackDataB = getStackedSums(filteredDataB, 0, 2, 1, 3)

        return [religion2020, cooperation2021, coopANDreligion, sortedCOMBO, stackDataR, filteredDataR, stackDataC, filteredDataC, stackDataB, filteredDataB]
    }

    function buttonClicked() {
        const min = parseInt(document.getElementById('leftEthVal').textContent)
        const max = parseInt(document.getElementById('rightEthVal').textContent)

        setMinYear(min - (min % 5))
        setMaxYear(max - (max % 5) + 4)

        setDataArray(fetchData(min - (min % 5), max - (max % 5)))
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "100%", width: "100%", }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "70%", width: "12%", position: "fixed", backgroundColor: "#25313C", borderRadius: "5px" }}>
                    <div>
                        <MultiRangeSlider
                            min={yearRange[0]}
                            max={yearRange[1] + 4}
                            cat={'Eth'}
                            onChange={({ min, max }) => {
                                console.log()
                            }}
                        />
                    </div>
                    <button style={{ width: "117px", height: "30px", fontSize: "12px", backgroundColor: "#E47639", color: "white", borderRadius: "10px" }} onClick={buttonClicked}> Submit </button>
                </div>
                <div style={{ height: "max-content", width: "85%", display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "17%", backgroundColor: "#25313C", borderRadius: "5px" }}>
                    <div className={'row'}>
                        <div className={'col centered-text'} style={{ 'font-weight': 'bold', color: "white", margin: "20px 0 20px 0" }}>
                            {
                                'Publications per Year by Ethnicity between ' + minYear + ' and ' + maxYear
                            }
                        </div>
                    </div>

                    <div className={"row"} style={{margin:"0 0 20px 0"}}>
                        <div className={'col-auto'}>
                            <LPLOT dataString={dataArray[0]}
                                title={'Religion'}
                                width={0.2}
                            />
                        </div>
                        <div className={'col-auto'}>
                            <LPLOT dataString={dataArray[1]}
                                title={'Cooperation'}
                                width={0.2}
                            />

                        </div>
                        <div className={'col-auto'}>
                            <LPLOT dataString={dataArray[2]}
                                title={'Cooperation and Religion'}
                                width={0.2}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div>
                            <BSPLOT title={'Religion'} dataObject={dataArray[4]}
                                dataString={dataArray[5]}
                                yearIndex={2} countIndex={3} />
                        </div>
                        <div>
                            <BSPLOT title={'Cooperation'} dataObject={dataArray[6]}
                                dataString={dataArray[7]}
                                yearIndex={2} countIndex={3} />
                        </div>
                        <div style={{margin:"0 0 50px 0"}}>
                            <BSPLOT title={'Cooperation and Religion'} dataObject={dataArray[8]}
                                dataString={dataArray[9]}
                                yearIndex={2} countIndex={3} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ethnicity;