import { filterByYear, getTopSums, getYearRange } from "../assets/utils";
import BPLOT from "./BPLOT";
import finalauthors from "../assets/finalAuthors";
import MultiRangeSlider from "./MultiRangeSlider";
import { useState } from "react";

const Author = () => {
    const yearRange = getYearRange(finalauthors, 2)

    const startData = fetchData(yearRange[0], yearRange[1])

    const [minYear, setMinYear] = useState(yearRange[0])
    const [maxYear, setMaxYear] = useState(yearRange[1] + 4)

    const [dataArray, setDataArray] = useState(startData)

    function fetchData(min, max) {
        const filteredData = filterByYear(finalauthors, min, max, 2)
        const rankDeptDataREL = getTopSums(filteredData, 10, 0, 3, 1, 'Religion2020')
        const rankDeptDataCOP = getTopSums(filteredData, 10, 0, 3, 1, 'Cooperation2021')
        const rankDeptDataBOTH = getTopSums(filteredData, 10, 0, 3, 1, 'CooperationAndReligion')

        return [rankDeptDataREL, rankDeptDataCOP, rankDeptDataBOTH]
    }

    function buttonClicked() {
        const min = parseInt(document.getElementById('leftAuthorVal').textContent)
        const max = parseInt(document.getElementById('rightAuthorVal').textContent)

        const filteredData = fetchData(min - (min % 5), max - (max % 5))
        setMinYear(min - (min % 5))
        setMaxYear(max - (max % 5) + 4)
        setDataArray(filteredData)
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "100%", width: "100%", }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "70%", width: "12%", position: "fixed", backgroundColor: "#25313C", borderRadius: "5px" }}>
                    <div>
                        <MultiRangeSlider
                            min={yearRange[0]}
                            max={yearRange[1] + 4}
                            cat={'Author'}
                            onChange={({ min, max }) => {
                                console.log()
                            }}
                        />
                    </div>
                    <button style={{ width: "117px", height: "30px", fontSize: "12px", backgroundColor: "#E47639", color: "white", borderRadius: "10px" }} onClick={buttonClicked}> Submit </button>
                </div>
                <div style={{ height: "max-content", width: "85%", display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "17%", backgroundColor: "#25313C", borderRadius: "5px" }}>
                    <div className={'row'}>
                        <div className={'col centered-text'} style={{ 'font-weight': 'bold', color: "white", margin: "20px 0 20px 0" }}>
                            {
                                'Top 10 Authors by Number of Publications between ' + minYear + ' and ' + maxYear
                            }
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div>
                            <BPLOT
                                title={'Religion'}
                                data={dataArray[0]}
                                height={0.5} />
                        </div>
                        <div>
                            <BPLOT
                                title={'Cooperation'}
                                data={dataArray[1]}
                                height={0.5} />
                        </div>
                        <div style={{margin:"0 0 50px 0"}}>
                            <BPLOT
                                title={'Religion and Cooperation'}
                                data={dataArray[2]}
                                height={0.5} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Author;