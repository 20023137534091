import { getRankDeptSums, getYearRange } from "../assets/utils";
import BPLOT from "./BPLOT";
import { useState } from "react";
import MultiRangeSlider from "./MultiRangeSlider";
import deptrank from "../assets/DepartmentLastRankByPentaSlice";
import $ from 'jquery'

const Departments = () => {

    const [isFirstLoad, setIsFirstLoad] = useState(true)

    const yearRange = getYearRange(deptrank, 3)
    const startData = fetchData(yearRange[0], yearRange[1], ['Psychology'])
    const uniqueDepts = getUniqueDepts()

    const [currentMin, setCurrentMin] = useState(yearRange[0])
    const [currentMax, setCurrentMax] = useState(yearRange[1] + 4)

    const [dataArray, setDataArray] = useState(startData)

    function getUniqueDepts() {
        let depts = {}
        deptrank.split('\n').forEach((row, i) => {
            const colValues = row.split(',')
            if (i !== 0 && colValues[0] !== 'NA' && !(depts[colValues[0]]) && colValues[0] !== '' && colValues[0]) {
                depts[colValues[0]] = 0
            }
        })

        let deptArray = []
        for (const [key, val] of Object.entries(depts)) {
            deptArray.push(key)
        }
        return deptArray
    }

    function fetchData(min, max, selectedDepts) {
        const rankDeptDataREL = getRankDeptSums('Religion2020', min, max, selectedDepts)
        const rankDeptDataCOP = getRankDeptSums('Cooperation2021', min, max, selectedDepts)
        const rankDeptDataBOTH = getRankDeptSums('CooperationAndReligion', min, max, selectedDepts)

        return [rankDeptDataREL, rankDeptDataCOP, rankDeptDataBOTH]
    }

    function getSelectedDepts() {
        const selected = []
        $('.form-check-input').each(function () {
            if (this.checked) {
                selected.push($(this).parent()[0].innerText)
            }
        })
        return selected
    }

    function buttonClicked() {
        const min = parseInt(document.getElementById('leftDeptVal').textContent)
        const max = parseInt(document.getElementById('rightDeptVal').textContent)
        const selectedDeptArray = getSelectedDepts()

        const filteredData = fetchData(min - (min % 5), max - (max % 5), selectedDeptArray)

        setCurrentMin(min - (min % 5))
        setCurrentMax(max - (max % 5) + 4)

        setDataArray(filteredData)

        window.scroll({
            top: $('.dept-col').position().top - 130,
            behavior: "smooth"
        })
    }

    $(document).ready(function () {
        if (isFirstLoad) {
            $('#PsychologyCheckbox').prop('checked', true)
            setIsFirstLoad(false)
        }
    })

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%", }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "70%", width: "12%", position: "fixed", backgroundColor: "#25313C", borderRadius: "5px" }}>
                    <div>
                        <MultiRangeSlider
                            min={yearRange[0]}
                            max={yearRange[1] + 4}
                            cat={'Dept'}
                            onChange={({ min, max }) => {
                                console.log()
                            }}
                        />
                    </div>
                    <button style={{ width: "117px", height: "30px", fontSize: "12px", backgroundColor: "#E47639", color: "white", borderRadius: "10px" }} onClick={buttonClicked}> Submit </button>
                </div>
                <div style={{ height: "max-content", width: "85%", display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "17%", backgroundColor: "#25313C", borderRadius: "5px" }}>
                    <div className={'row'}>
                        <div className={'col centered-text'} style={{ 'font-weight': 'bold', color: "white", margin: "20px 0 20px 0" }}>
                            {
                                'Publications between ' + currentMin + ' and ' + currentMax + ' by Department'
                            }
                        </div>
                    </div>

                    <div style={{ display:"flex", flexDirection: "row", flexFlow:"row wrap", margin: '50px 0 0 100px' }}>
                        {
                            uniqueDepts.map((val, index) => {
                                return (
                                    <div className={'form-check'} style={{ height: "40px", width: "250px" }}>
                                        <input className={"form-check-input d-flex flex-row"} type={"checkbox"} value={""}
                                            id={val + "Checkbox"} />
                                        <label  htmlFor={"flexCheckDefault"}
                                            style={{ lineHeight: "17px", color:"white" }}>
                                            {val}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div style={{ margin: "0 0 50px 0" }}>
                        {
                            dataArray.map((colData, i) => {
                                let collectionName = ''
                                switch (i) {
                                    case 0:
                                        collectionName = 'Religion'
                                        break
                                    case 1:
                                        collectionName = 'Cooperation'
                                        break
                                    case 2:
                                        collectionName = 'Religion and Cooperation'
                                        break
                                    default:
                                        collectionName = ''
                                }
                                return (
                                    <div>
                                        {
                                            colData.map((data, index) => {
                                                const selectedDepts = getSelectedDepts()
                                                return (
                                                    <BPLOT data={data}
                                                        title={selectedDepts[index] + ' Department ' + collectionName + ' Publications'} />
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Departments;